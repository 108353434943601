<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchDto" size="mini">
                <el-form-item label="事件等级">
                    <el-select v-model="searchDto.level" clearable placeholder="全部" style="width: 80px">
                        <el-option label="故障" value=1 style="color:#F56C6C"/>
                        <el-option label="告警" value=2 style="color:#E6A23C"/>
                        <el-option label="通知" value=3 style="color:#409EFF"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="事件分类">
                    <el-select v-model="searchDto.classify" clearable placeholder="全部" style="width: 100px">
                        <el-option v-for="item in classifySelect" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="产品型号">
                    <el-select v-model="searchDto.productModelId" clearable placeholder="全部">
                        <el-option v-for="item in productModelSelect" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="事件名称">
                    <el-input v-model="searchDto.name" placeholder="事件名称" clearable style="width: 250px"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQueryClickEvent">查询</el-button>
                    <el-button type="primary" @click="openEsaioEventDefDetailPage(null)">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border stripe size="small" max-height="550px" v-loading="listLoading"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}">
                <el-table-column type="index" label="序号" align="center" width="50px"/>
                <el-table-column prop="level" label="事件等级" align="center" width="70px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.level === '1'" style="color:#F56C6C">故障</span>
                        <span v-else-if="scope.row.level === '2'" style="color:#E6A23C">告警</span>
                        <span v-else-if="scope.row.level === '3'" style="color:#409EFF">通知</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="productModelName" label="产品型号" width="150px"/>
                <el-table-column prop="classify" label="事件分类" width="90px" align="center" :formatter="eventClassifyFormatter"/>
                <el-table-column v-for="(item) in languageList" prop="i18nList" :show-overflow-tooltip="true"
                     :label="'事件' + item.languageName + '名称'" min-width="100px">
                    <template slot-scope="scope">
                        {{getTableColumnEventName(scope.row.i18nList, item.languageCode)}}
                    </template>
                </el-table-column>
                <el-table-column prop="noticeDing" label="通知" width="50px" align="center" :formatter="noticeDingFormatter"/>
                <el-table-column width="80px" label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="option-btn-div">
                            <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                                <i class="el-icon-edit option-btn-normal" @click="openEsaioEventDefDetailPage(scope.row.id)"/>
                            </el-tooltip>
                            <el-tooltip v-if="enableDeleteBtn(scope.row)" v-delTabIndex effect="dark" content="删除" placement="top">
                                <i class="el-icon-delete option-btn-normal" style="color: #F56C6C"
                                    @click="removeEsaioEventDef(scope.row.id)"/>
                            </el-tooltip>
                            <i v-else class="el-icon-delete option-btn-disable"/>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableTotal"
                        :current-page.sync="searchDto.pageNum"
                        :page-sizes="[10, 50, 100]"
                        :page-size="searchDto.pageSize"
                        @size-change="handleSizeChangeEvent"
                        @current-change="handleCurrentChangeEvent"/>
            </div>
            <EsaioEventDefDetail ref="EsaioEventDefDetailPage" @queryEsaioEventDefList="queryEsaioEventDefList"
                :productModelList="productModelSelect" :classifySelect="classifySelect" :languageList="languageList"/>
        </div>
    </div>
</template>

<script>
    import NavBreadcrumb from '@/components/NavBreadcrumb.vue';
    import EsaioEventDefDetail from '@/views/esaio/event/esaio-event-def-detail.vue';
    import {
        queryEsaioEventDefList,
        queryEventClassifyList,
        queryEventDefI18nLanguageList,
        queryProductModeList,
        removeEsaioEventDef
    } from '@/api/esaio/event/esaioEventDefApi';
    import message from '@/utils/message';
    import Global from '@/components/Global';

    export default {

        name: 'EsaioEventDefList',
        components: {NavBreadcrumb, EsaioEventDefDetail},
        data() {

            return {

                searchDto: {

                    pageNum: 1,
                    pageSize: 10,
                    level: '',
                    name: '',
                    classify: '',
                    productModelId: ''
                },
                aioMiniProductModelCode: 'AioMini',
                classifySelect: [],
                productModelSelect: [],
                languageList: [],
                listLoading: false,
                tableData: [],
                tableTotal: 0
            };
        },
        methods: {

            initEsaioEventDefPage() {

                this.queryEventDefI18nLanguageList();
                this.queryEventClassifyList();
                this.queryProductModeList();
                this.queryEsaioEventDefList();
            },
            openEsaioEventDefDetailPage(id) {

                this.$refs.EsaioEventDefDetailPage.initEsaioEventDefDetailPage(id);
            },
            removeEsaioEventDef(id) {

                this.$confirm('是否要删除该事件定义？', '提示', {type: 'warning'})
                    .then(() => {

                        removeEsaioEventDef({id : id}).then((res) => {

                            if (res.code === Global.response_status_success_obj) {

                                message.success('删除成功');
                            } else {

                                message.error(res.msg);
                            }
                            this.queryEsaioEventDefList();
                        }).catch(err => {

                            message.error('请求失败,请稍后再试');
                            console.error(err);
                        });
                    });
            },
            handleQueryClickEvent() {

                this.searchDto.pageNum = 1;
                this.queryEsaioEventDefList();
            },
            queryEsaioEventDefList() {

                this.listLoading = true;
                queryEsaioEventDefList(this.searchDto).then(res => {

                    this.listLoading = false;
                    if (res.code === Global.response_status_success_obj) {

                        this.tableTotal = res.data.total;
                        this.tableData = res.data.result;
                    }
                }).catch(err => {

                    console.error(err);
                    this.listLoading = false;
                    message.error('请求失败,请稍后再试');
                });
            },
            handleCurrentChangeEvent(val) {

                this.searchDto.pageNum = val;
                this.queryEsaioEventDefList();
            },
            handleSizeChangeEvent(val) {

                this.searchDto.pageSize = val;
                this.searchDto.pageNum = 1;
                this.queryEsaioEventDefList();
            },
            queryEventDefI18nLanguageList() {

                queryEventDefI18nLanguageList().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.languageList = res.data;
                    }
                }).catch(err => {

                    console.error(err);
                    message.error('请求失败,请稍后再试');
                });
            },
            queryEventClassifyList() {

                queryEventClassifyList().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.classifySelect = res.data;
                    }
                }).catch(err => {

                    console.error(err);
                    message.error('请求失败,请稍后再试');
                });
            },
            queryProductModeList() {

                queryProductModeList().then(res => {

                    if (res.code === Global.response_status_success_obj) {

                        this.productModelSelect = res.data;
                    }
                }).catch(err => {

                    console.error(err);
                    message.error('请求失败,请稍后再试');
                });
            },
            noticeDingFormatter(row, column, cellValue) {

                if (cellValue === '1') {

                    return '是';
                } else if (cellValue === '0') {

                    return '否';
                } else {

                    return '-';
                }
            },
            eventClassifyFormatter(row, column, cellValue) {

                let eventClassify = this.classifySelect.find(item => {

                    return item.value === cellValue;
                });
                if (eventClassify) {

                    return eventClassify.name;
                } else {

                    return '-';
                }
            },
            getLanguageName(languageCode) {

                for (let i = 0; i < this.languageList.length; i++) {

                    if (languageCode === this.languageList[i].languageCode) {

                        return this.languageList[i].languageName;
                    }
                }
            },
            getTableColumnEventName(i18nList, languageCode) {

                let i18nLanguage = i18nList.find(item => {

                    return item.languageCode === languageCode;
                });
                if (i18nLanguage) {

                    return i18nLanguage.eventName;
                } else {

                    return '-';
                }
            },
            enableDeleteBtn(row) {

                return row.productModelCode === this.aioMiniProductModelCode;
            }
        },
        mounted() {

            this.initEsaioEventDefPage();
        }
    }
</script>

<style lang="scss" scoped>
    .pagination {

        margin-top: 15px;
        text-align: right;
    }
    .option-btn-div {

        display: flex;
        justify-content: space-around
    }
    .option-btn-normal {

        font-size: 18px;
        color: #409EFF;
        cursor: pointer;
    }
    .option-btn-disable {

        font-size: 18px;
        color: #CCCCCC;
        cursor: not-allowed;
    }
</style>
